export const disposalObjects = [
  {
    className: "뷰테인가스용기",
    disposals: [
      "1. 구멍을 뚫는 등 잔여가스를 제거한 뒤 배출합니다",
      "2. 통풍이 잘 되는 곳에서 제거합니다",
    ],
  },
  {
    className: "건전지",
    disposals: [
      "1. 전지를 제품에서 분리하여 배출합니다",
      "2. 아파트, 주민센터 등의 전용수거함에 배출합니다",
      "3. 휴대폰 배터리는 휴대폰과 함께 배출합니다",
    ],
  },
  {
    className: "배달용기",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 재질별로 분리하여 배출합니다",
    ],
  },
  {
    className: "종이팩",
    disposals: [
      "1. 내용물을 비우고 세척하여 말린 후 배출합니다",
      "2. 빨대 등 다른 재질은 분리하여 배출합니다",
      "3. 전용수거함에 배출합니다",
      "4. 전용수거함이 없는 경우 종이류와 구분되도록 묶어 종이류로 배출합니다",
    ],
  },
  {
    className: "비닐봉투",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 흩날리지 않도록 한 데 담아 배출합니다",
      "3. 페트병 등 다른 폐기물에 붙어있는 것을 분리하여 배출합니다",
    ],
  },
  {
    className: "살충제",
    disposals: ["1. 잔여물을 제거한 뒤 배출합니다"],
  },
  {
    className: "스티로폼",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 부착상표 등 다른 재질은 분리하여 배출합니다",
      "3. 완충재인 발포합성수지 포장재는 가급적 반납합니다",
    ],
  },
  {
    className: "식용유",
    disposals: ["1. 음식물이 섞이지 않게 모아 전용수거함에 배출합니다"],
  },
  {
    className: "유리병",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 깨지지 않도록 주의하여 배출합니다",
      "3. 반용기보증금 대상 유리병은 소매점 등에서 환급합니다",
    ],
  },
  {
    className: "신문지",
    disposals: ["1. 젖지 않도록 하고, 펴서 쌓은 후 한 데 묶어 배출합니다"],
  },
  {
    className: "아이스팩",
    disposals: [
      "1. 깨끗하게 하여 전용 수거함에 배출합니다",
      "2. 통째로 배출할 때에는 종량제 봉투를 이용합니다",
    ],
  },
  {
    className: "종이컵",
    disposals: ["1. 내용물을 비우고 세척하여 배출합니다"],
  },
  {
    className: "캔",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 플라스틱 뚜껑 등 다른 재질은 분리하여 배출합니다",
    ],
  },
  {
    className: "페트병",
    disposals: [
      "1. 내용물을 비우고 세척하여 배출합니다",
      "2. 부착상표 등 다른 재질은 분리하여 배출합니다",
    ],
  },
  {
    className: "형광등",
    disposals: [
      "1. 깨지지 않도록 주의하여 전용수거함에 배출합니다",
      "2. 깨진 형광등은 다치치 않게 신문지 등으로 감싸 일반쓰레기로 배출합니다",
    ],
  },
  {
    className: "고철",
    disposals: ["1. 내용물을 비우고 세척하여 배출합니다"],
  },
  {
    className: "노트/책자",
    disposals: ["1. 스프링 든 자른 재질은 분리하여 배출합니다"],
  },
  {
    className: "농약 용기",
    disposals: ["1. 폐농약 플라스틱 용기, 봉지류만 그물망에 모아 배출합니다"],
  },
  {
    className: "농촌 폐비닐",
    disposals: [
      "1. 하우스용과 멀칭용 비닐을 구분하여 이물질을 제거하고 묶어 배출합니다",
      "2. 공동집하장 또는 일 수거차량 진입이 가능한 장소에 배출합니다",
    ],
  },
  {
    className: "비철금속",
    disposals: ["1. 내용물을 비우고 세척하여 배출합니다"],
  },
  {
    className: "의류",
    disposals: [
      "1. 전용수거함에 배출합니다",
      "2. 전용수거함이 없는 지역에서는 한 데 모아 배출합니다",
    ],
  },
  {
    className: "종이상자",
    disposals: ["1. 테이프 등 종이류와 다른 재질은 제거한 후 배출합니다."],
  },
];

export const about = [
  {
    title: "이 사이트는 무슨 사이트인가요?",
    content: "분리배출을 독려하기 위해 다양한 서비스를 제공하는 사이트입니다.",
  },
  {
    title: "이 사이트의 목적은 무엇인가요?",
    content:
      "인공지능을 실생활에 직접 적용할 수 있음을 보여주고, 분리배출을 통한 재활용 비율을 올리는 것 입니다.",
  },
  {
    title: "누가 개발했나요?",
    content: "운정고등학교 1학년 윤영주, 진재희가 개발한 사이트입니다.",
  },
];

export const searchError = [
  "깔끔한 배경에서 사진을 찍어주세요",
  "물건이 선명하게 나오도록 사진을 찍어주세요",
  "물건의 특징이 잘 드러나도록 찍어주세요",
  "이미지의 밝기를 알맞게 조절해주세요",
];
